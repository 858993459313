import React from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {Button, Card, CardContent, Chip, Grid, Typography} from '@mui/material'
import AppModal from 'web-common/components/modal/AppModal'
import UserContactEdit from 'web-common/views/profile/UserContactEdit'
import {UserContactData} from 'web-common/models/Profile'
import CustomerService from 'web-common/services/CustomerService'
import GridComposer from 'web-common/components/layouts/GridComposer'

interface ValidateUserEmailViewProps extends WithTranslation {}

interface ValidateUserEmailViewState {
  contact?: UserContactData
  validateModal: {
    show: boolean
  }
}

class ValidateUserEmailView extends React.Component<
  ValidateUserEmailViewProps,
  ValidateUserEmailViewState
> {
  state: ValidateUserEmailViewState = {
    contact: undefined,
    validateModal: {show: false}
  }

  componentDidMount() {
    CustomerService.get().then((user) => {
      this.setState({
        contact: user.contact
      })
    })
  }

  onSuccessfullyValidate() {
    this.setState((prev) => {
      prev.contact!.emailVerified = true
      prev.validateModal.show = false
    })
    window.location.reload()
  }

  closeModal() {
    this.setState({
      validateModal: {show: false}
    })
  }

  render() {
    if (!this.state.contact || this.state.contact.emailVerified) {
      return null
    }
    return (
      <>
        <Card>
          <CardContent>
            <Grid container direction={'row'}>
              <Grid item xs={8}>
                <GridComposer>
                  <Typography variant={'body1'}>
                    {this.props.t(
                      'common:fnd-common-validate-your-email-received-notifications'
                    )}
                  </Typography>
                  <Typography variant={'body2'} component={'span'}>
                    {this.state.contact.email}
                    <Chip
                      style={{marginLeft: '10px'}}
                      size={'small'}
                      color={
                        this.state.contact.emailVerified ? 'primary' : 'error'
                      }
                      label={this.props.t(
                        this.state.contact.emailVerified
                          ? 'common:fnd-common-verified'
                          : 'common:fnd-common-not-verified'
                      )}
                    />
                  </Typography>
                </GridComposer>
              </Grid>
              <Grid item xs={4} textAlign={'end'} alignSelf={'end'}>
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  size={'medium'}
                  onClick={() => this.setState({validateModal: {show: true}})}
                >
                  {this.props.t('common:fnd-common-verify')}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <AppModal
          open={this.state.validateModal.show}
          onClose={this.closeModal.bind(this)}
        >
          <UserContactEdit
            type={'email'}
            currentContact={this.state.contact.email}
            onSave={this.onSuccessfullyValidate.bind(this)}
            onCancel={this.closeModal.bind(this)}
            onError={(message) => console.log(message)}
          />
        </AppModal>
      </>
    )
  }
}

export default withTranslation()(ValidateUserEmailView)
