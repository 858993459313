import React, {ReactNode, useEffect, useMemo, useState} from 'react'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import {Button, Link, Stack, Typography} from '@mui/material'
import {
  Survey,
  SurveyAnswers,
  SurveyAnswersMapperItem,
  SurveyData,
  SurveyElementChoices,
  SurveyElementLocationPicker,
  SurveyElementLocationPickerValue,
  SurveyElementTimePickerRange,
  SurveyLocalizedString,
  SurveyRendererService,
  SurveyAnswersMapper,
  SurveyTranslationSyncer
} from '@fnd/survey'
import {useTranslation} from 'react-i18next'
import RegionService, {
  RegionSelectionData
} from 'web-common/services/RegionService'
import AppDate from 'web-common/components/date/AppDate'
import Money from 'web-common/components/money/Money'
import AppGallery from 'web-common/components/gallery/AppGallery'
import Loading from 'web-common/components/loading/Loading'
import {
  AppFileDocument,
  AppFileGalleryImage,
  DocumentSet,
  ImageSet
} from 'web-common/services/AppFileService'

const FileAnswer = ({
  files,
  disabledPreview
}: {
  files: string[]
  disabledPreview?: boolean
}) => {
  const [loading, setLoading] = useState(true)
  const [images, setImages] = useState<ImageSet[]>([])
  const [docs, setDocs] = useState<DocumentSet[]>([])
  useEffect(() => {
    ;(async () => {
      const imagesAcc: ImageSet[] = []
      const docsAcc: DocumentSet[] = []
      await Promise.all(
        files.map(async (file) => {
          const isImage = new RegExp(/\.(jpg|jpeg|png|svg|gif|heic)/, 'i')
          if (isImage.test(file)) {
            const appFile = new AppFileGalleryImage(file)
            imagesAcc.push(await appFile.toResource())
          } else {
            const appFile = new AppFileDocument(file)
            docsAcc.push(await appFile.toResource())
          }
        })
      )
      setImages(imagesAcc)
      setDocs(docsAcc)
      setLoading(false)
    })()
  }, [files])

  return (
    <Loading
      loading={loading}
      render={() => (
        <>
          {images && (
            <AppGallery
              images={images}
              showAll={disabledPreview}
              allowPreview={!disabledPreview}
            />
          )}
          {docs.map((doc) => (
            <a
              key={doc.link}
              href={doc.link}
              target={'_blank'}
              rel="noreferrer"
            >
              {doc.name}
            </a>
          ))}
        </>
      )}
    />
  )
}

const LocationPickerAnswer = (props: {
  answer: SurveyElementLocationPickerValue
}) => {
  const [t, i18n] = useTranslation()
  const [regions, setRegions] = useState<RegionSelectionData[]>([])

  useEffect(() => {
    RegionService.getAll().then(setRegions)
  }, [setRegions])

  const getRegionName = (region: RegionSelectionData): string => {
    return (
      (region as unknown as Record<string, string>)[`name:${i18n.language}`] ??
      region.name
    )
  }

  const getAddress = () => {
    if (props.answer.range) {
      return (
        <Typography variant={'body1'}>
          {t('common:fnd-common-location-picker-answer-value', {
            range: (props.answer.range ?? 0) / 1000,
            address:
              props.answer.address ||
              t('common:fnd-common-widget-location-picker-pinned-location')
          })}
        </Typography>
      )
    }
    return props.answer.address ? (
      <Typography variant={'body1'}>{props.answer.address}</Typography>
    ) : undefined
  }

  return (
    <Stack direction={'column'}>
      {getAddress()}
      <Typography variant={'body1'}>
        {regions
          ?.filter((r) => props.answer.regions.includes(r._id))
          .map(getRegionName)
          .join(', ')}
      </Typography>
      <Link
        href={`https://www.google.com/maps/search/?api=1&query=${props.answer.lat},${props.answer.lon}`}
        target={'_blank'}
      >
        {t('common:fnd-common-widget-location-picker-show-map')}
      </Link>
    </Stack>
  )
}

const RegionAnswer = ({meta}: {meta: SurveyAnswersMapperItem}) => {
  const [, i18n] = useTranslation()
  const [regions, setRegions] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const answer = (
      Array.isArray(meta.answer.value)
        ? meta.answer.value
        : [meta.answer.value as unknown as string]
    ) as string[]
    RegionService.getAll().then((regions) => {
      const allRegions = regions
        .filter((region) => answer.includes(region._id))
        .map((region) => {
          const cRegions = region as unknown as Record<string, string>
          if (cRegions[`name:${i18n.language}`]) {
            return cRegions[`name:${i18n.language}`]
          }
          if (cRegions[`name:en`]) {
            return cRegions[`name:en`]
          }
          return region.name
        })
        .join(', ')
      setRegions(allRegions)
      setLoading(false)
    })
  }, [meta, i18n.language])

  return <Loading loading={loading} render={() => <>{regions}</>} />
}

interface AnswerSectionProps {
  question: SurveyLocalizedString
  answers: ReactNode
}

function AnswerSection(props: AnswerSectionProps) {
  const [, l10n] = useTranslation()
  const question = props.question[l10n.language] ?? props.question['en']

  return (
    <Stack>
      <Typography variant={'subtitle1'} component={'span'}>
        {question}
      </Typography>
      <Typography variant={'body1'} component={'span'}>
        {props.answers}
      </Typography>
    </Stack>
  )
}

export interface QuestionnaireAnswersProps {
  language: string
  surveyData?: SurveyData
  survey?: Survey
  answers: SurveyAnswers
  showEmptyAnswers?: boolean
  disableImagePreview?: boolean
  // Show first `n` answers and then show 'show more' button
  limit?: boolean
}

const QuestionnaireAnswers = (props: QuestionnaireAnswersProps) => {
  const [t, i18n] = useTranslation()
  const [showMore, setShowMore] = useState(false)
  const answersLimit = 8
  const survey = useMemo(() => {
    if (props.surveyData) {
      const localizedSurvey = (data: SurveyData) =>
        SurveyTranslationSyncer.localizeSurvey(
          i18n.language,
          data.model,
          data.l10n ?? {}
        )
      return SurveyRendererService.buildSurvey(
        localizedSurvey(props.surveyData),
        props.surveyData.templates?.map(localizedSurvey) ?? []
      )
    } else if (props.survey) {
      return props.survey
    }
    throw new Error('Survey is missing')
  }, [props.surveyData, props.survey, i18n.language])
  const mapper = useMemo(
    () => new SurveyAnswersMapper(survey, props.answers),
    [survey, props.answers]
  )
  const answers = useMemo(() => mapper.getQuestionAnswerMeta(), [mapper])

  const extractChoicesAnswer = (meta: SurveyAnswersMapperItem) => {
    const values = Array.isArray(meta.answer.value)
      ? (meta.answer.value as string[])
      : [meta.answer.value as unknown as string]
    const hasOther = values.includes('other')
    const getChoices = (values: string[]) =>
      values
        .map((answer) => {
          const choice = (meta.question as SurveyElementChoices).choices?.find(
            (c) => c.value === answer
          )
          if (choice === undefined) {
            return answer
          }
          return choice?.label[i18n.language] ?? choice?.label['en'] ?? '--'
        })
        .join(', ')
    const result = [
      getChoices(values.filter((v) => v !== 'other')),
      hasOther ? meta.answer.otherValue : undefined
    ]
    return <>{result.filter((r) => !!r).join(', ')}</>
  }

  const extractRegionsAnswer = (meta: SurveyAnswersMapperItem) => {
    return <RegionAnswer meta={meta} />
  }

  const extractDatepickerAnswer = (meta: SurveyAnswersMapperItem) => {
    const timestamps = Array.isArray(meta.answer.value)
      ? (meta.answer.value as string[])
      : [meta.answer.value as unknown as string]
    return (
      <Stack direction={'row'} divider={<> - </>} spacing={2}>
        {timestamps.map((timestamp, i) => (
          <AppDate
            timestamp={parseInt(timestamp)}
            format={'date'}
            key={timestamp + '-' + i}
          />
        ))}
      </Stack>
    )
  }

  const extractTimepickerAnswer = (meta: SurveyAnswersMapperItem) => {
    const numberToTime = (n: number) =>
      [
        Math.floor((n / 3600) % 24)
          .toString()
          .padStart(2, '0'),
        Math.floor((n / 60) % 60)
          .toString()
          .padStart(2, '0')
      ].join(':')

    const rangeToTime = (num: SurveyElementTimePickerRange) => {
      if (num.length !== 2) {
        return [numberToTime(0), numberToTime(0)]
      }
      return num.map(numberToTime)
    }
    const generateLabel = (range: SurveyElementTimePickerRange) => {
      const time = rangeToTime(range)
      if (range[0] === 0) {
        return t('common:fnd-common-widget-time-before', {time: time[1]})
      }
      if (range[1] === 3600 * 24) {
        return t('common:fnd-common-widget-time-after', {time: time[0]})
      }
      return t('common:fnd-common-widget-time-between', {
        time1: time[0],
        time2: time[1]
      })
    }
    const range = (
      (meta.answer.value as string | string[]).includes('other')
        ? meta.answer.otherValue
        : meta.answer.value
    ) as SurveyElementTimePickerRange
    return (
      <Stack direction={'row'} divider={<> - </>} spacing={1}>
        {generateLabel(range)}
      </Stack>
    )
  }

  const extractBudgetAnswer = (meta: SurveyAnswersMapperItem) => {
    console.log('extractBudgetAnswer')
    console.log(meta)
    if (
      (meta.answer.value as number) === -1 ||
      isNaN(Number(meta.answer.value))
    ) {
      return <>{t('common:fnd-common-widget-budget-type-budget')}</>
    }
    return <Money value={meta.answer.value as number} />
  }

  const extractFileAnswer = (meta: SurveyAnswersMapperItem) => {
    return (
      <FileAnswer
        files={meta.answer.value as unknown as string[]}
        disabledPreview={props.disableImagePreview}
      />
    )
  }

  const extractLocationType = (meta: SurveyAnswersMapperItem) => {
    if (!meta.answer.value) {
      return ''
    }
    return t(
      `common:fnd-common-survey-widget-location-type-${meta.answer.value!.toString()}`
    )
  }

  const extractLocationPicker = (meta: SurveyAnswersMapperItem) => {
    const answer = meta.answer.value as SurveyElementLocationPicker['value']
    if (!answer) {
      return ''
    }
    return <LocationPickerAnswer answer={answer} />
  }

  const renderAnswer = (meta: SurveyAnswersMapperItem) => {
    const answer = () => {
      switch (meta.question.type) {
        case 'text':
          return <>{meta.answer.value}</>
        case 'choices':
        case 'dropdown':
        case 'price-modifier':
          return extractChoicesAnswer(meta)
        case 'regions':
          return extractRegionsAnswer(meta)
        case 'datepicker':
          return extractDatepickerAnswer(meta)
        case 'time-picker':
          return extractTimepickerAnswer(meta)
        case 'budget':
          return extractBudgetAnswer(meta)
        case 'file-uploader':
          return extractFileAnswer(meta)
        case 'location-type':
          return extractLocationType(meta)
        case 'location-picker':
          return extractLocationPicker(meta)
        default:
          return <>{JSON.stringify(meta.answer.value, null, 2)}</>
      }
    }
    return (
      <AnswerSection
        question={meta.question.title}
        answers={answer()}
        key={meta.pageName + meta.elementName}
      />
    )
  }

  const limit = props.limit && !showMore ? answersLimit : answers.length
  const label = showMore ? t('Show less') : t('Show more')
  const Icon = showMore
    ? KeyboardArrowUpRoundedIcon
    : KeyboardArrowDownRoundedIcon
  return (
    <Stack spacing={3}>
      {answers
        .filter((a) => (props.showEmptyAnswers ? true : !!a.answer.value))
        .slice(0, limit)
        .map(renderAnswer)}
      {props.limit && (
        <Button
          color={'primary'}
          variant={'text'}
          endIcon={<Icon />}
          onClick={() => setShowMore(!showMore)}
        >
          {label}
        </Button>
      )}
    </Stack>
  )
}

export default QuestionnaireAnswers
