import React, {ReactNode} from 'react'
import {Button, List, ListItem, TextField} from '@mui/material'
import {withTranslation, WithTranslation} from 'react-i18next'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import {SearchableService} from 'web-common/models/CategoriesAndServices'
import ServicesService from 'web-common/services/ServicesService'
import SearchLogger from 'web-common/services/SearchLogger'
import withRouter, {RouteComponentProps} from 'web-common/utilities/router'

export interface SearchServiceResultProps {
  service: SearchableService
  query: string
  data: any
}

interface SearchServiceState extends React.ComponentState {
  searchQuery: string
  categories: SearchableService[]
  serviceNotAvailableModal: boolean
  showResult: boolean
}

interface SearchServiceProps extends RouteComponentProps, WithTranslation {
  compact?: boolean
  logResult?: boolean
  suggestService?: boolean
  placeholder?: string
  label?: ReactNode
  size?: 'small' | 'medium'
  resultTemplate: React.ComponentType<any>
  onSelectService: (service: SearchableService) => void
  onSuggestService?: (query: string) => void
  onResultList?: (services: SearchableService[]) => void
  onFocus?: () => void
  onBlur?: () => void
  groupByCategory?: boolean
  showNoResults?: boolean
  // Generic data to pass to template if needed
  data?: any
  excludeInactive?: boolean
}

class SearchService extends React.Component<
  SearchServiceProps,
  SearchServiceState
> {
  readonly minQueryLength = 2

  state: SearchServiceState = {
    serviceNotAvailableModal: false,
    searchQuery: '',
    categories: [],
    showResult: false
  }

  focusableElements: number = 0
  focusIndex: number = -1
  focusSelector: string = `search-service-focus-element-${Date.now()}-`

  // Debounce handler
  debounceHandler: number | undefined

  // UI is searching for category
  isSearchCategories = false

  // Search from category list
  searchFromCategories(term: string) {
    this.setState(
      {
        categories: ServicesService.search(
          term,
          this.props.logResult ?? false,
          this.props.groupByCategory,
          this.props.excludeInactive
        )
      },
      () => {
        this.props.onResultList?.(this.state.categories)
      }
    )
  }

  onSearchFieldChange(event: any) {
    const query = event.target.value
    this.setState(
      {
        searchQuery: query,
        showResult: query !== ''
      },
      () => {
        if (this.debounceHandler) {
          window.clearTimeout(this.debounceHandler)
        }
        this.debounceHandler = window.setTimeout(() => {
          this.isSearchCategories = query.length > 0
          this.searchFromCategories(query)
        }, 300)
      }
    )
  }

  onServiceSelect(item: SearchableService) {
    if (this.props.logResult) {
      SearchLogger.completeSessionSuccess(item.service.id)
    }
    //if (item.active) {
    this.isSearchCategories = false
    this.focusIndex = -1
    this.focusableElements = 0
    this.setState(
      {
        categories: [],
        searchQuery: ''
      },
      () => {
        this.props.onSelectService(item)
      }
    )
  }

  async componentDidMount() {
    if (this.props.logResult && this.props.groupByCategory) {
      console.log('[Warning] Logged results are shown grouped')
    }

    await ServicesService.load(this.props.i18n.language)
    this.setState({
      categories: []
    })
  }

  generateSuggestedList() {
    if (!this.state.showResult) {
      return null
    }
    let elements = 0
    const list = this.state.categories.map((item, index) => {
      elements = index
      const key = 'id' + item.service.id + item.category.id
      const Tmp = this.props.resultTemplate
      return (
        <React.Fragment key={key}>
          <ListItem
            button
            onClick={this.onServiceSelect.bind(this, item)}
            onKeyDown={this.keyboardNavigation.bind(this)}
            className={`${this.focusSelector}${index}`}
          >
            <Tmp
              service={item}
              data={this.props.data}
              query={this.state.searchQuery}
            />
          </ListItem>
          {((_) => {
            if (index < this.state.categories.length - 1) {
              return <Divider />
            }
          })()}
        </React.Fragment>
      )
    })

    if (
      this.props.showNoResults &&
      this.state.categories.length === 0 &&
      this.isSearchCategories &&
      this.state.searchQuery.length >= this.minQueryLength
    ) {
      list.push(
        <ListItem
          key="suggest-new-category"
          className={`${this.focusSelector}${++elements}`}
        >
          <Box textAlign={'center'} sx={{typography: 'body1'}}>
            {this.props.t('common:fnd-common-search-no-result')}
          </Box>
        </ListItem>
      )
    }

    // Add "suggest category"
    if (this.isSearchCategories && this.props.suggestService) {
      list.push(
        <ListItem
          key="suggest-new-category"
          button={true}
          className={`${this.focusSelector}${++elements}`}
          onKeyDown={this.keyboardNavigation.bind(this)}
          onClick={this.props.onSuggestService?.bind(
            this,
            this.state.searchQuery
          )}
        >
          <Button
            color={'primary'}
            fullWidth={true}
            variant={'contained'}
            tabIndex={-1}
          >
            {this.props.t('common:fnd-common-suggest-new-category')}
          </Button>
        </ListItem>
      )
    }
    this.focusableElements = elements
    if (list.length === 0) {
      return null
    }
    const style: any = {
      zIndex: 1,
      position: 'absolute',
      background: 'white',
      top: '41px',
      width: '100%'
    }
    return (
      <Paper
        style={this.props.compact ? style : {}}
        aria-live={'polite'}
        role="region"
        aria-atomic="true"
        aria-label={`Results from search`}
      >
        <List style={{padding: '0px'}}>{list}</List>
      </Paper>
    )
  }

  onCloseList() {
    this.setState({
      showResult: false
    })
  }

  onFocus() {
    this.setState(
      {
        showResult: true
      },
      () => {
        this.props.onFocus?.call(this)
      }
    )
  }

  onBlur() {
    this.props.onBlur?.call(this)
  }

  render() {
    return (
      <ClickAwayListener onClickAway={this.onCloseList.bind(this)}>
        <Box position={'relative'}>
          <TextField
            variant={'outlined'}
            role="searchbox"
            value={this.state.searchQuery}
            placeholder={
              this.props.placeholder ??
              this.props.t('common:fnd-common-placeholder-search-service')
            }
            label={this.props.label}
            onChange={this.onSearchFieldChange.bind(this)}
            type={'search'}
            fullWidth={true}
            size={this.props.size ?? 'small'}
            InputProps={{
              style: {background: 'white'}
            }}
            inputProps={{
              className: `${this.focusSelector}-1`
            }}
            onFocus={this.onFocus.bind(this)}
            onBlur={this.onBlur.bind(this)}
            onKeyDown={this.keyboardNavigation.bind(this)}
          />
          <span key={this.state.searchQuery}>
            {this.generateSuggestedList()}
          </span>
        </Box>
      </ClickAwayListener>
    )
  }

  private keyboardNavigation(e: React.KeyboardEvent<HTMLDivElement>) {
    const code = e.keyCode
    /**
     * 13 - enter
     * 27 - escape
     * 38 - up arrow
     * 40 - down arrow
     */
    if ([9, 13, 27, 38, 40].includes(code)) {
      const results = this.state.searchQuery === '' ? 0 : this.focusableElements
      let index = this.focusIndex
      const state: any = {}
      if (code === 38 || (code === 9 && e.shiftKey)) {
        if (code === 9 && results === 0) {
          // NO RESULTS, NOT NEED TO CRAW WITH TAB
          return true
        }
        // KEYBOARD UP
        if (index - 1 < -1) {
          if (code === 9) {
            // If user hit tab on the last element move to next tab index on the page
            return true
          }
          index = results
        } else {
          index -= 1
        }
      } else if (code === 40 || code === 9) {
        if (code === 9 && results === 0) {
          // NO RESULTS, NOT NEED TO CRAW WITH TAB
          return true
        }
        // KEYBOARD DOWN
        if (index + 1 > results) {
          if (code === 9) {
            // If user hit tab on the last element move to next tab index on the page
            return true
          }
          index = -1
        } else {
          index += 1
        }
      } else if (code === 13) {
        // ENTER
        if (index === -1 && this.isSearchCategories) {
          // Select first result on enter
          if (this.state.categories.length > 0) {
            this.onServiceSelect(this.state.categories[0])
          } else {
            this.props.onSuggestService?.(this.state.searchQuery)
          }
        } else if (index === this.state.categories.length) {
          // Suggest category clicked
        } else if (index > -1 && index < this.state.categories.length) {
          // List item clicked
          this.onServiceSelect(this.state.categories[index])
        }
      } else {
        // ESCAPE
        index = -1
        state.categories = []
        state.searchQuery = ''
        this.isSearchCategories = false
        this.setState(state)
      }
      // UPDATE STATE
      this.focusIndex = index
      ;(
        document.querySelector(`.${this.focusSelector}${index}`) as HTMLElement
      )?.focus()
      e.preventDefault()
      e.stopPropagation()
      return false
    }
  }
}

const routed = withRouter(SearchService)
export default withTranslation()(routed)
