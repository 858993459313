import {AppConfig} from 'AppConfig'
import {BehaviorSubject} from 'rxjs'
import {OverridableComponent} from '@mui/material/OverridableComponent'
import {SvgIconTypeMap} from '@mui/material'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenterRounded'
import SchoolIcon from '@mui/icons-material/SchoolRounded'
import LocalActivityIcon from '@mui/icons-material/LocalActivityRounded'
import SpaIcon from '@mui/icons-material/SpaRounded'
import BuildIcon from '@mui/icons-material/BuildRounded'
import {
  CategoryData,
  SearchableService
} from 'web-common/models/CategoriesAndServices'
import SearchService from 'web-common/services/SearchService'
import SearchLogger, {
  MatchData,
  SearchResultLogData
} from 'web-common/services/SearchLogger'
import {
  ApiConfig,
  RequestService
} from 'web-common/services/request/RequestService'
import {CachedRequest} from 'web-common/services/request/Request'

const paths = {
  services: {
    tree: {}
  },
  requireToken: false,
  toString: () => AppConfig.api.base!
}

class ServicesService extends RequestService {
  protected api = new ApiConfig<typeof paths>(paths)
  protected static instance: ServicesService
  private readonly minQueryLength = 2

  static shared() {
    if (!ServicesService.instance) {
      ServicesService.instance = new ServicesService()
    }
    return ServicesService.instance
  }

  private cachedRequest = this.enhancedRequest(CachedRequest.create)
  rawResult?: CategoryData
  serviceList: SearchableService[] = []
  language: string = 'en'
  // TODO: refactor
  private initializedLoad?: Promise<SearchableService[]>

  // @deprecated
  ready = new BehaviorSubject(false)

  private categoryToIcon: {
    [id: string]: OverridableComponent<SvgIconTypeMap>
  } = {
    business: BusinessCenterIcon,
    classes_and_lessons: SchoolIcon,
    events: LocalActivityIcon,
    health_and_wellness: SpaIcon,
    home_services: BuildIcon
  }

  iconFromCategory(mainCategoryId: string) {
    return this.categoryToIcon[mainCategoryId]
  }

  /**
   * Get categoryModel as observable
   */
  get() {
    return this.api.paths.services.tree.get<CategoryData>(this.cachedRequest)()
  }

  // Clear cached services
  clearCache() {
    this.serviceList = []
    this.cachedRequest.clearCache(this.api.paths.services.tree)
  }

  /**
   * Fetch and store categories for current session
   * @param language
   */
  load(language: string): Promise<SearchableService[]> {
    if (this.initializedLoad !== undefined) {
      return this.initializedLoad
    }
    this.initializedLoad = new Promise(async (r) => {
      this.language = language
      try {
        this.rawResult = await this.get()
        const categories: SearchableService[] =
          SearchService.categoriesToSearchable(this.rawResult, language)
        this.serviceList = categories
        this.ready.next(true)
        r(categories)
        this.initializedLoad = undefined
      } catch (e) {
        r([])
      }
    })
    return this.initializedLoad
  }

  rebuildSearchable(withLanguage: string) {
    if (this.rawResult === undefined) {
      throw new Error('Before rebuilding, load must be called')
    }
    const categories: SearchableService[] =
      SearchService.categoriesToSearchable(this.rawResult, withLanguage)
    this.serviceList = categories
  }

  // Search for service by string
  search(
    searchFor: string,
    log: boolean,
    groupByCategory?: boolean,
    excludeInactive?: boolean
  ): SearchableService[] {
    const query = searchFor.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' ')
    if (query.length >= this.minQueryLength) {
      const logData: MatchData[] = []
      const categories = SearchService.search(
        query,
        this.serviceList,
        (serviceId, matches) => {
          logData.push({
            serviceId: serviceId,
            words: matches.map((m) => m.word)
          })
        },
        excludeInactive
      )
      if (log) {
        this.logSearch(searchFor, logData)
      }
      if (groupByCategory) {
        const appear: string[] = []
        return categories.filter((item) => {
          if (!appear.includes(item.category.id)) {
            appear.push(item.category.id)
            return true
          }
          return false
        })
      }
      return categories
    }
    return []
  }

  logSearch(query: string, matchedServices: MatchData[]) {
    const data: SearchResultLogData = {
      query: query,
      // sort desc *based on number of matches
      matches: matchedServices.sort((a, b) => b.words.length - a.words.length)
    }
    SearchLogger.log(data)
  }

  // Get service by id
  getServiceById(serviceId: string): SearchableService | undefined {
    return this.serviceList.find((item) => item.service.id === serviceId)
  }

  getCategoryById(categoryId: string): SearchableService[] | undefined {
    return this.serviceList.filter((item) => item.category.id === categoryId)
  }

  getMainCategoryById(categoryId: string): SearchableService[] {
    return this.serviceList.filter(
      (item) => item.parentCategory.id === categoryId
    )
  }
}

export default ServicesService.shared()
