import {FileStorageAPI} from 'web-common/services/files/FileStorageFactory'
import {
  ApiConfig,
  RequestService
} from 'web-common/services/request/RequestService'
import {FileUtils} from 'web-common/services/files/FileUtils'
import {AxiosRequestConfig} from 'axios'
import kc from 'web-common/services/auth'
import {AppConfig} from 'AppConfig'

const paths = {
  files: {
    list: {},
    'well-known': {}
  },
  requireToken: false,
  toString: () => AppConfig.api.storage ?? ''
}

export class AWSFileStorage extends RequestService implements FileStorageAPI {
  protected api: ApiConfig<typeof paths> = new ApiConfig<typeof paths>(paths)

  static instance: AWSFileStorage

  static shared() {
    return (
      AWSFileStorage.instance ??
      (AWSFileStorage.instance = new AWSFileStorage())
    )
  }

  private static withParams(
    config: AxiosRequestConfig = {},
    secured: boolean
  ): AxiosRequestConfig {
    if (secured) {
      return {
        ...config,
        headers: {
          Authorization: 'Bearer ' + kc.instance.token,
          ...config.headers
        }
      }
    }
    return config
  }

  deleteResource(relativePath: string, secured: boolean): Promise<void> {
    return this.api.paths.files.delete<void>(this.request)(
      AWSFileStorage.withParams(
        {
          params: {
            file: relativePath
          }
        },
        secured
      )
    )
  }

  getResource(relativeResourceURI: string, secured: boolean): Promise<string> {
    return this.api.paths.files.get<string>(this.request)(
      AWSFileStorage.withParams(
        {
          params: {
            file: relativeResourceURI
          }
        },
        secured
      )
    )
  }

  getWellKnownResource(relativePath: string): string {
    return (
      this.api.paths.files['well-known'].toString() + '?file=' + relativePath
    )
  }

  list(relativePathDir: string, secured: boolean): Promise<string[]> {
    return this.api.paths.files.list.get<string[]>(this.request)(
      AWSFileStorage.withParams(
        {
          params: {
            path: relativePathDir
          }
        },
        secured
      )
    )
  }

  async uploadResource(
    resource: File | string,
    relativePath: string,
    fileName: string,
    secured: boolean
  ): Promise<string> {
    const formData = new FormData()
    if (typeof resource === 'string') {
      formData.append('file', await FileUtils.base64ToFile(resource, fileName))
    } else {
      formData.append('file', resource)
    }

    await this.api.paths.files.post<string>(this.request)(
      formData,
      AWSFileStorage.withParams(
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          params: {
            path: relativePath,
            file: fileName
          }
        },
        secured
      )
    )

    return relativePath.replace(/^\/|\/$/g, '') + '/' + fileName
  }
}
