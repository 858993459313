import React from 'react'
import {
  AppFormComponent,
  AppFormInputComponent
} from 'web-common/components/formBuilder/AppFormFactory'
import {Box, Chip, ListItemText, TextField} from '@mui/material'
import SearchService, {
  SearchServiceResultProps
} from 'web-common/components/services/SearchService'
import {SearchableService} from 'web-common/models/CategoriesAndServices'
import ServicesService from 'web-common/services/ServicesService'
import {WithTranslation, withTranslation} from 'react-i18next'

export interface AppFormServiceData {
  multiple: boolean
  delimiter: string
}

interface AppFormServiceState {
  ready: boolean
}

interface AppFormServiceProps extends AppFormComponent, WithTranslation {}

class AppFormService extends AppFormInputComponent<
  AppFormServiceProps,
  AppFormServiceState
> {
  state: AppFormServiceState = {
    ready: false
  }

  componentDidMount() {
    ServicesService.load(this.props.i18n.language)
      .then((_) => {
        this.setState({ready: true})
      })
      .catch((e) => {
        console.log(e)
      })
  }

  onChange(item: SearchableService) {
    const {onChangeCallback, ...rest} = {...this.props}
    if (this.props.data && this.props.data.delimiter) {
      const value: string[] = (this.props.value ?? '')
        .split(this.props.data.delimiter)
        .filter((i) => i !== '' && i !== item.service.id)
      value.push(item.service.id)
      this.props.onChangeCallback(rest, value.join(this.props.data.delimiter))
    } else {
      this.props.onChangeCallback(rest, item.service.id)
    }
  }

  onRemove(serviceId?: string) {
    const {onChangeCallback, ...rest} = {...this.props}
    if (this.props.data && this.props.data.delimiter) {
      const value = (this.props.value ?? '')
        .split(this.props.data.delimiter)
        .filter((i) => i !== '')

      const removeIndex = value.indexOf(serviceId!)
      if (removeIndex !== -1) {
        value.splice(removeIndex, 1)
      }
      this.props.onChangeCallback(rest, value.join(this.props.data.delimiter))
    } else {
      this.props.onChangeCallback(rest, '')
    }
  }

  renderResultItem(item: SearchServiceResultProps) {
    return (
      <ListItemText
        primary={item.service.service.name}
        secondary={
          item.service.category.name + ' / ' + item.service.parentCategory.name
        }
      />
    )
  }

  extractServiceName(id: string) {
    return ServicesService.getServiceById(id)?.service.name ?? ''
  }

  extractValue() {
    if (!this.props.value) {
      return null
    }
    const values = () => {
      if (this.props.data && this.props.data.delimiter) {
        return this.props
          .value!.split(this.props.data.delimiter)
          .map((v) => (
            <Chip
              label={this.extractServiceName(v)}
              size={'small'}
              key={v}
              onDelete={this.onRemove.bind(this, v)}
              style={{margin: '0 8px'}}
            />
          ))
      } else {
        return (
          <Chip
            label={this.extractServiceName(this.props.value!)}
            size={'small'}
            onDelete={this.onRemove.bind(this, undefined)}
            style={{margin: '0 8px'}}
          />
        )
      }
    }
    return <Box marginTop={'1rem !important'}>{values()}</Box>
  }

  render() {
    if (!this.state.ready) {
      return <TextField disabled placeholder={this.props.placeholder} />
    }
    return (
      <>
        <SearchService
          logResult={false}
          size={'small'}
          compact
          onSelectService={this.onChange.bind(this)}
          resultTemplate={this.renderResultItem.bind(this)}
          placeholder={this.props.placeholder}
          label={this.props.label}
        />
        {this.extractValue()}
      </>
    )
  }
}

export default withTranslation()(AppFormService)
