import {FormControlLabel, Radio, RadioGroup} from '@mui/material'
import React, {useContext} from 'react'
import {SurveyFactoryElementBaseProps} from 'web-common/components/survey/ui/elements/SurveyElementFactory'
import {SurveyElementPriceModifier} from '@fnd/survey'
import CheckboxGroup, {
  CheckboxGrouping,
  CheckboxGroupProps
} from 'web-common/components/survey/ui/elements/helpers/CheckboxGroup'
import {SRContext} from 'web-common/components/survey/ui/view/SurveyRendererView'

export default function SurveyElementUIPriceModifier({
  field,
  element
}: SurveyFactoryElementBaseProps<SurveyElementPriceModifier>) {
  const context = useContext(SRContext)
  const name = `${element.name}`

  function renderCheckbox(
    field: Omit<CheckboxGroupProps, 'id'>,
    element: SurveyElementPriceModifier
  ) {
    return (
      <CheckboxGroup {...field} id={element.name}>
        {element.choices?.map((option) => (
          <FormControlLabel
            value={option.value}
            key={
              name + ' ' + option.value + ' ' + option.label[context.language]
            }
            control={<CheckboxGrouping />}
            label={option.label[context.language]}
          />
        ))}
      </CheckboxGroup>
    )
  }

  function renderRadio(
    field: Omit<CheckboxGroupProps, 'id'>,
    element: SurveyElementPriceModifier
  ) {
    return (
      <RadioGroup {...field} id={element.name}>
        {element.choices?.map((option) => (
          <FormControlLabel
            value={option.value}
            key={
              name + ' ' + option.value + ' ' + option.label[context.language]
            }
            control={<Radio />}
            label={option.label[context.language]}
          />
        ))}
      </RadioGroup>
    )
  }

  const renderMethod = element.multiple ? renderCheckbox : renderRadio
  return renderMethod(field, element)
}
